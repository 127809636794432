import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage as Translation } from 'react-intl';
import withTrack from 'HOCs/withTrack/withTrack';
import withRouter from 'HOCs/withRouter';
import CategoryBrowsing from 'Components/CategoryBrowsing/CategoryBrowsing.mobile';
import { locationToString } from 'Helpers/url';
import css from './NotFound.mobile.scss';
import Page from 'Components/Page/Page';
import SearchHeader from '../../components/HeaderWrapper/Headers/SearchHeader/SearchHeader.mobile';
import { getLocaleZendeskUrl } from 'Selectors/config';
import { withConfig } from 'HOCs/withConfig/withConfig';
import { fileNames } from 'Constants/imageUrls';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { WEBP_TYPE } from 'Constants/images';
import { CampaingHelpers } from 'olx-autos-landing-page';

export class NotFound extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        track: PropTypes.func.isRequired,
        zendeskUrl: PropTypes.string,
        marketConfig: PropTypes.object,
        isPaidCampaign: PropTypes.bool
    }

    constructor(props) {
        super(props);
        const olxAutosConfig = props.marketConfig.get('olxAutos');

        this.isMxCl = olxAutosConfig && olxAutosConfig.isMXCL || false;
    }

    componentDidMount() {
        this.props.track('not_found');
        this.props.track('page_error_404', {
            target_url: locationToString(this.props.location)
        });
    }

    renderCategoryBrowsingHeader() {
        return (
            <div className={ css.categoryBrowsingHeader }>
                <div className={ css.categoryBrowsingTitle }>
                    <Translation id="orBrowseCategories" />
                </div>
            </div>
        );
    }

    render() {
        const header = (
            this.isMxCl && this.props.isPaidCampaign
                ? <SearchHeader hideSearchBar hideActionButtons hideHamburger enableSearchInput={ false } isPaidCampaign />
                : <SearchHeader />
        );

        return (
            <Page header={ header } >
                <div
                    className={ css.notFoundContainer }
                    data-aut-id="notFoundContainer"
                >
                    <div className={ css.header }>
                        <div className={ css.notFoundImage }>
                            <ImageWrapper
                                fileName={ fileNames.PAGE_404 }
                                types={ WEBP_TYPE }
                            />
                        </div>
                        <div className={ css.notFoundText }>
                            <div className={ css.title }><Translation id="oops" /></div>
                            <div className={ css.subtitle }>
                                <Translation id="cantFind" />
                                <br />
                                <Translation id="trySearch" />
                            </div>
                            <div className={ css.error }><Translation id="error404" /></div>
                            <div className={ css.usefulLinks }>
                                <Translation id="helpfulLinks" />
                                <div className={ css.links }>
                                    <Translation id="home" >
                                        { text =>
                                            (<Link title={ text } className={ css.link } to="/">
                                                { text }
                                            </Link>)
                                        }
                                    </Translation>
                                    { this.props.zendeskUrl
                                    && <Translation id="help" >
                                        { text => (<Link
                                            title={ text }
                                            to={ this.props.zendeskUrl }
                                            className={ css.link }
                                            target="_blank"
                                            rel="nofollow noopener noreferrer"
                                            data-aut-id="btnHamHelp"
                                        >{ text }
                                        </Link>) }
                                    </Translation>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {!this.isMxCl && <div className={ css.categories }>
                        <CategoryBrowsing
                            location={ this.props.location }
                            header={ this.renderCategoryBrowsingHeader() }
                        />
                    </div>}
                </div>
            </Page>
        );
    }
}

export const mapStateToProps = (state, ownProps) => ({
    zendeskUrl: getLocaleZendeskUrl(state, ownProps),
    isPaidCampaign: CampaingHelpers.isPaidCampaign(ownProps && ownProps.location && ownProps.location.query, ownProps?.marketConfig?.get())
});

export default compose(
    withConfig('marketConfig'),
    withTrack,
    withRouter,
    connect(mapStateToProps)
)(NotFound);
